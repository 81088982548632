export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon'
  },
  {
    title: 'Clients',
    route: 'clients',
    fa_icon: 'user-tie'
  },
  {
    title: 'Terminals',
    icon: 'CpuIcon',
    children: [
      {
        title: 'Terminals',
        route: 'terminals'
      },
      {
        title: 'Terminal models',
        route: 'terminal_models'
      },
      {
        title: 'Firmware packages',
        route: 'firmware_packages'
      }
    ]
  },
  {
    title: 'Projects',
    route: 'projects',
    icon: 'ClipboardIcon'
  }
]
